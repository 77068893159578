import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, List, ListItem, ListItemText,
    ListItemIcon, IconButton, Typography, InputAdornment, Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Check, Search } from '@mui/icons-material';
import styles from './select-dialog.module.css';

interface SelectDialogProps {
    categories: { key: string, subcategories: { buckets: { key: string, doc_count: number }[] } }[];
    selectedCategories: string[];
    selectedSubCategories: string[];
    filterIndex: number;
    onChange: (selectedCategories: string[], selectedSubCategories: string[], filterIndex: number) => void;
    open: boolean;
    onClose: () => void;
}

const SelectDialog: React.FC<SelectDialogProps> = ({
    categories,
    selectedCategories,
    selectedSubCategories,
    filterIndex,
    onChange,
    open,
    onClose
}) => {
    const [search, setSearch] = useState('');
    const [filteredCategories, setFilteredCategories] = useState(categories);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearch(searchTerm);
        const filtered = searchTerm
            ? categories.map((category) => ({
                ...category,
                subcategories: {
                    ...category.subcategories,
                    buckets: category.subcategories.buckets.filter((sub) =>
                        sub.key.toLowerCase().includes(searchTerm)
                    ),
                },
            }))
            : categories;
        setFilteredCategories(filtered);
    };

    const handleClose = () => {
        // Call the onClose function provided by the parent component
        onClose();
    };

    const handleCategoryClick = (categoryName: string) => {
        const category = categories.find((cat) => cat.key === categoryName);
        if (category && category.subcategories && Array.isArray(category.subcategories.buckets)) {
            const allItems = category.subcategories.buckets.map((sub) => sub.key);
            const allSelected = selectedCategories.includes(categoryName);

            if (allSelected) {
                onChange(
                    selectedCategories.filter((item) => item !== categoryName),
                    selectedSubCategories.filter((item) => !allItems.includes(item)),
                    filterIndex
                );
            } else {
                onChange(
                    [...selectedCategories, categoryName],
                    [...selectedSubCategories, ...allItems],
                    filterIndex
                );
            }
        }
    };

    const handleSubCategoryClick = (categoryName: string, subCategoryName: string) => {
        let updatedCategories = [...selectedCategories];
        let updatedSubCategories = [...selectedSubCategories];

        if (selectedSubCategories.includes(subCategoryName)) {
            updatedSubCategories = updatedSubCategories.filter((item) => item !== subCategoryName);
        } else {
            updatedSubCategories.push(subCategoryName);
        }

        const category = categories.find((cat) => cat.key === categoryName);
        const allSubCategories = category?.subcategories.buckets.map((sub) => sub.key) || [];

        if (allSubCategories.every((sub) => updatedSubCategories.includes(sub))) {
            if (!updatedCategories.includes(categoryName)) {
                updatedCategories.push(categoryName);
            }
        } else {
            updatedCategories = updatedCategories.filter((item) => item !== categoryName);
        }

        onChange(updatedCategories, updatedSubCategories, filterIndex);
    };

    const handleSelectAllClick = () => {
        const allCategories = categories.map((category) => category.key);
        const allSubCategories = categories.flatMap((category) =>
            category.subcategories.buckets.map((sub) => sub.key)
        );

        if (selectedCategories.length === allCategories.length && selectedSubCategories.length === allSubCategories.length) {
            onChange([], [], filterIndex);
        } else {
            onChange(allCategories, allSubCategories, filterIndex);
        }
    };

    const isCategorySelected = (categoryName: string) => selectedCategories.includes(categoryName);
    const isSubCategorySelected = (subCategoryName: string) => selectedSubCategories.includes(subCategoryName);

    useEffect(() => {
        setFilteredCategories(categories);
    }, [categories]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" classes={{ paper: styles.dialogPaper }} PaperProps={{ style: { maxWidth: '600px' } }}>
            <DialogTitle className={styles.dialogTitle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <TextField
                        fullWidth
                        placeholder="Search categories / subcategories..."
                        value={search}
                        onChange={handleSearchChange}
                        variant="outlined"
                        className={styles.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            classes: {
                                notchedOutline: styles.noOutline,
                            },
                        }}
                    />
                </Box>
                <div className={styles.selectedCount}>
                    <Typography variant="subtitle2"  >
                        {selectedCategories.length + selectedSubCategories.length} CATEGORIES / SUBCATEGORIES SELECTED
                    </Typography>
                    <Button onClick={handleSelectAllClick}>
                        {selectedCategories.length === categories.length && selectedSubCategories.length === categories.flatMap(cat => cat.subcategories.buckets).length ? 'Deselect All' : 'Select All'}
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <List className={styles.categoryList}>
                    {filteredCategories?.map((category) => (
                        category.subcategories.buckets.length > 0 && (
                            <div key={category.key}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" height={80}>
                                    <div>
                                        <Typography variant="h5" className={styles.categoryTitle}>{category.key}</Typography>
                                        <Typography variant="body2" className={styles.secondaryText}>{`${category.subcategories.buckets.length} subcategories`}</Typography>
                                    </div>
                                    <IconButton
                                        size='small'
                                        className={`${styles.iconButton} ${isCategorySelected(category.key) ? styles.selected : ''}`}
                                        onClick={() => handleCategoryClick(category.key)}
                                    >
                                        {isCategorySelected(category.key) ? <Check /> : <AddIcon />}
                                    </IconButton>
                                </Box>
                                {category.subcategories.buckets.map((sub, index) => (
                                    <ListItem key={sub.key} className={index === category.subcategories.buckets.length - 1 ? `${styles.listItem} ${styles.lastItem}` : styles.listItem}>
                                        <ListItemText primary={sub.key.toLowerCase().replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())} secondary={`${sub.doc_count} items`} />
                                        <ListItemIcon>
                                            <IconButton
                                                size='small'
                                                onClick={() => handleSubCategoryClick(category.key, sub.key)}
                                                className={`${styles.iconButton} ${isSubCategorySelected(sub.key) ? styles.selected : ''}`}
                                            >
                                                {isSubCategorySelected(sub.key) ? <Check /> : <AddIcon />}
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                ))}
                            </div>
                        )
                    ))}
                </List>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <Button variant="contained" color="primary" className={styles.continueButton} onClick={handleClose}>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SelectDialog;

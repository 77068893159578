import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";

import SettingsContainer from "components/SettingsContainer";
import { getProductsByBusinessId } from "redux/nativeEcommerceSlice";
import { format, headCells } from "../InventoryManagementConstants";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Box,
  tableCellClasses,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MainCard from "components/MainCard";

import { getComparator, stableSort } from "helpers/table.helper";
import { NoProductsFound } from "./no-products.component";

const BusinessInventory = ({ businessId }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, isLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const handleNavigateToProduct = (productId: string) => {
    navigate(`/businesses/${businessId}/ecommerce/inventory/view/${productId}`);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const filteredProducts = allProducts?.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // Apply sorting to filteredProducts
    const sortedProducts = stableSort(
      [...filteredProducts],
      getComparator(isAsc ? "asc" : "desc", property),
    );
    setAllProducts(sortedProducts); // Update the state with the sorted products
  };

  useEffect(() => {
    (async () => {
      isLoading(true);
      try {
        const response = await dispatch(
          getProductsByBusinessId({
            businessId,
          }),
        );
        isLoading(false);
        const products = response.payload;
        setAllProducts(products);
      } catch (error) {
        setError("Error fetching products");
        isLoading(false);
      }
    })();
  }, [businessId, dispatch]);

  const showNoProductsError = !loading && (error || allProducts.length === 0);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f2f4f5",
      color: "#6c6c6c",
      padding: ".5rem 1rem",
      // Apply border radius only to the first and last cells in the header row
      "&:first-child": {
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
      },
      "&:last-child": {
        borderTopRightRadius: "15px",
        borderBottomRightRadius: "15px",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const EnhancedTableHead = (props: any) => {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler =
      (property: string, sortProperty: string) => (event: any) => {
        onRequestSort(event, sortProperty);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.sortProperty ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.sortProperty}
                direction={orderBy === headCell.sortProperty ? order : "asc"}
                onClick={createSortHandler(headCell.id, headCell.sortProperty)}
              >
                {headCell.label}
                {orderBy === headCell.sortProperty ? <span></span> : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <SettingsContainer>
      <MainCard sx={{ marginTop: "1rem" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            display: { xs: "block", sm: "flex" }, // Adjust breakpoints as needed
          }}
        >
          <Typography variant="h3" component="h3" fontWeight="900">
            Products
          </Typography>
          {!showNoProductsError && (
            <Box>
              <TextField
                placeholder="Search Products"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    minWidth: "300px",
                  },
                }}
                fullWidth
                size="small"
              />
            </Box>
          )}
        </Box>
        {!showNoProductsError ? (
          <div>
            <Box>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(filteredProducts, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row: any) => {
                        return (
                          <StyledTableRow
                            key={row?.id}
                            sx={{
                              cursor: "pointer",
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            onClick={() => handleNavigateToProduct(row?.id)}
                            hover={true}
                          >
                            <TableCell>{row?.name}</TableCell>
                            <TableCell>{row?.brand?.name}</TableCell>
                            <TableCell>{format(row?.category)}</TableCell>
                            <TableCell>{format(row?.subCategory)}</TableCell>
                            <TableCell>{format(row?.strainType)}</TableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredProducts?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </div>
        ) : (
          <NoProductsFound isProductDetailsPage={false} />
        )}
      </MainCard>
    </SettingsContainer>
  );
};

export default BusinessInventory;

export const categories = [
  { value: "FLOWER", label: "FLOWER" },
  { value: "PRE_ROLLS", label: "PRE-ROLLS" },
  { value: "EDIBLES", label: "EDIBLES" },
  { value: "CONCENTRATES", label: "CONCENTRATES" },
  { value: "TINCTURES", label: "TINCTURES" },
  { value: "VAPORIZERS", label: "VAPORIZERS" },
  { value: "CBD", label: "CBD" },
  { value: "ACCESSORIES", label: "ACCESSORIES" },
  { value: "MERCH", label: "MERCH" },
  { value: "NONE", label: "N/A" },
];

export const strains = [
  { value: "INDICA", label: "INDICA" },
  { value: "SATIVA", label: "SATIVA" },
  { value: "HIGH_CBD", label: "HIGH-CBD" },
  { value: "HYBRID", label: "HYBRID" },
  { value: "NOT_APPLICABLE", label: "N/A" },
];

export const fields = [
  { value: "description", label: "Description" },
  { value: "strainType", label: "Strain Type" },
  { value: "category", label: "Category" },
  { value: "subCategory", label: "Subcategory" },
  { value: "brand", label: "Brand" },
  { value: "name", label: "Name" },
  { value: "menuType", label: "Menu Type" },
  { value: "images", label: "Images" },
  { value: "posCategory", label: "PoS Category" },
  { value: "terpenes", label: "Terpenes" },
  { value: "cannabinoids", label: "Cannabinoids" },
  { value: "potency", label: "Potency" },
  { value: "variants", label: "Variants" },
  { value: "staffPick", label: "Staff Pick" },
  { value: "effects", label: "Effects" },
  { value: "tagList", label: "Tags" },
];

export const effects = [
  { value: "CALM", label: "Calm" },
  { value: "CLEAR_MIND", label: "Clear Mind" },
  { value: "CREATIVE", label: "Creative" },
  { value: "ENERGETIC", label: "Energetic" },
  { value: "FOCUSED", label: "Focused" },
  { value: "FULL_BODY_HIGH", label: "Full Body High" },
  { value: "HAPPY", label: "Happy" },
  { value: "HEAD_HIGH", label: "Head High" },
  { value: "HUNGRY", label: "Hungry" },
  { value: "IMMEDIATE", label: "Immediate" },
  { value: "INSPIRED", label: "Inspired" },
  { value: "LONG_LASTING", label: "Long Lasting" },
  { value: "RELAXED", label: "Relaxed" },
  { value: "SLEEPY", label: "Sleepy" },
  { value: "UPLIFTED", label: "Uplifted" },
];

export const format = (field: string) => {
  if (field === "NOT_APPLICABLE") {
    return "N/A";
  }
  const strings = field.split("_");
  const formattedField = strings
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase())
    .join("-");
  return formattedField;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}-${year}`;
};

export const formatDiffField = (diffField: any) => {
  const field = fields.find((f) => f.value === diffField);
  return field ? field.label : diffField;
};
export const headCells = [
  {
    id: "name",
    numeric: false,
    bool: false,
    disablePadding: true,
    label: "Name",
    sortProperty: "name",
  },
  {
    id: "brand",
    numeric: false,
    disablePadding: false,
    label: "Brand",
    sortProperty: "brand.name",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
    sortProperty: "category",
  },
  {
    id: "subcategory",
    numeric: false,
    disablePadding: false,
    label: "Subcategory",
    sortProperty: "subCategory",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    sortProperty: "strainType",
  },
];

export const daysOfWeek = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const formatDisplayTime = (time: string) => {
  if (time === "0000") return "Closed";
  const hours = parseInt(time.slice(0, 2), 10);
  const minutes = parseInt(time.slice(2), 10);
  const ampm = hours >= 12 ? "PM" : "AM";
  const displayHours = hours % 12 || 12;
  return `${displayHours}:${minutes.toString().padStart(2, "0")}${ampm}`;
};

export const timeToMinutes = (timeStr: string) => {
  const hours = parseInt(timeStr.slice(0, 2), 10);
  const minutes = parseInt(timeStr.slice(2), 10);
  return hours * 60 + minutes;
};

export const toTitleCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatTime = (time: { hours: number; minutes?: number }) => {
  return `${time.hours.toString().padStart(2, "0")}${(time.minutes ?? 0).toString().padStart(2, "0")}`;
};
